import { CheckCircleIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import AdsContainer from "@sid/core/components/common/AdsContainer";
import Button from "@sid/core/components/common/Button";
import { FieldInput } from "@sid/core/components/common/Input";
import NewFooter from "@sid/core/components/common/NewFooter";
import TogglePassword, {
  useTogglePassword,
} from "@sid/core/components/common/TogglePassword";
import MainHeading from "@sid/core/components/home/MainHeading";
import NewLandingLayout from "@sid/core/components/home/NewLandingLayout";
import Spinner from "@sid/core/components/icon/Spinner";
import SeoComponent from "@sid/core/components/SeoComponent";
import api, { handleAxioError } from "@sid/core/util/api";
import internal from "@sid/core/util/internal";
import { mongo } from "@sid/core/util/mongo";
import axios from "axios";
import { Form, Formik } from "formik";
import { GetServerSidePropsContext } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

type Props = {
  link: { short: string };
};

const ProtectedLinkPage = ({ link }: Props) => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const timeoutRef = useRef<any>(null);
  const secret = useTogglePassword();
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = async (values, { setErrors }) => {
    try {
      const { data: _data } = await api().post(
        `/links/password/${link.short}`,
        {
          password: values.password,
        }
      );

      timeoutRef.current = setTimeout(() => {
        window.location = _data.data?.long_url;
      }, 2000);

      setIsSuccess(true);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        handleAxioError(e, { setErrors, toast, t, onError: null });
      }
    }
  };

  useEffect(() => {
    if (!link.short) {
      push("/404");
    }
  }, [link?.short]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  if (!link.short) {
    return <Fragment />;
  }

  return (
    <NewLandingLayout bgColor="#131428">
      <SeoComponent title={t("enter_key_to_continue")} />
      <div className="container-xl mx-auto relative md:px-6">
        <MainHeading activeSection={0}>
          <div className="mt-16 rounded-md p-6">
            <AdsContainer name="home_sid_protected_link_head" />

            <div className="p-6 flex flex-col items-center">
              <h1 className="text-lg md:text-3xl font-bold font-montserrat text-center mb-2">
                <LockClosedIcon className="mr-1 w-7 inline-block -mt-1" />
                <span className="text-red-500">s.id</span>/{link?.short}
              </h1>
              {isSuccess ? (
                <div className="flex items-center text-center mt-8">
                  <CheckCircleIcon className="w-6 mr-1 text-green-600" />
                  {t("you_will_redirect")}
                </div>
              ) : (
                <div className="">
                  <p className="md:text-lg text-center">
                    {t("enter_key_to_continue")}
                  </p>
                  <Formik initialValues={{ password: "" }} onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                      <Form className="mt-2">
                        <div className="mb-3 relative">
                          <FieldInput
                            type={secret.isOpen ? "text" : "password"}
                            name="password"
                            placeholder="------"
                            className="!py-2 pr-10 w-full text-xl text-gray-600"
                            disabled={isSubmitting}
                            required
                          />
                          <TogglePassword {...secret} />
                        </div>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          className="w-full flex justify-center items-center"
                        >
                          {isSubmitting && (
                            <Spinner className="w-4 animate-spin mr-2" />
                          )}
                          {t("continue")}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
            <div className="text-sm text-center text-gray-300 mb-8">
              <p>
                This link is generated user content and be carefull for
                phishing/scam/malware. We never ask for your information
                details.
              </p>
              <p>
                If you received this link within a suspicious email, phone
                calls, or other messages. Please do not go further.
              </p>
              <p>
                <Link href="/page/report" className="text-red-500 underline">
                  Report link
                </Link>{" "}
                if you think this link is suspicious.
              </p>
            </div>
          </div>
        </MainHeading>
        <NewFooter />
      </div>
    </NewLandingLayout>
  );
};

export async function getServerSideProps({
  locale,
  params,
}: GetServerSidePropsContext) {
  try {
    const m = await mongo();
    let statusCode = 200;
    const link: any = {};
    let isSubscribe = false;

    try {
      const { data: result } = await internal("links", {
        short: params?.short,
      });

      if (result.data) {
        const l = result.data;
        if (l.blocked) {
          statusCode = 403;
        } else {
          if (
            l.detail &&
            l.microsite === false &&
            !l.blocked &&
            l?.detail?.protected === true
          ) {
            link.short = l.short;
          } else {
            statusCode = 302;
          }
          const subs = await m.getUserSubscription(l.user_id);
          isSubscribe = subs.stillActive;
        }
      } else {
        statusCode = 404;
      }
    } catch (e) {
      console.error(e);
      statusCode = 500;
    }

    const settings = (await m.getSetting(
      ["ads"],
      ["home_sid_protected_link_head"]
    )) as any;

    if (isSubscribe) {
      settings.ads.enabled = false;
    }

    return {
      props: {
        ...(await serverSideTranslations(locale || "en")),
        statusCode,
        link,
        ...settings,
      },
    };
  } catch (e) {
    console.error(e);
    return {
      props: {
        code: 503,
      },
    };
  }
}

export default ProtectedLinkPage;
